/**
 *- Configuration for the scheduler
 */
import Team from '../data/equipe'
import Events from '../data/events'

export default {
  minHeight: '80vh',
  rowHeight: 50,
  barMargin: 4,
  eventColor: 'indigo',
  resources: Team,
  events: Events,
  startDate: new Date(2017, 0, 1, 6),
  endDate: new Date(2017, 0, 1, 15),
  columns: [
    {
      type: 'resourceInfo',
      text: 'Equipe',
      width: 200,
      showEventCount: false,
      showRole: true,
    },
    {
      text: 'Nbr tasks',
      editor: false,
      renderer: data => `${data.record.events.length || ''}`,
      align: 'center',
      sortable: (a, b) => (a.events.length < b.events.length ? -1 : 1),
      width: 100,
    },
  ],
  viewPreset        : {
    base           : 'hourAndDay',
    tickWidth      : 20,
    columnLinesFor : 0,
    headers        : [
        {
            unit       : 'd',
            align      : 'center',
            dateFormat : 'ddd DD MMM'
        },
        {
            unit       : 'h',
            align      : 'center',
            dateFormat : 'HH'
        }
    ]
},
features    : {
  stripe     : true,
  timeRanges : true,
  eventMenu  : {
      items : {
          // custom item with inline handler
          unassign : {
              text   : 'Unassign',
              icon   : 'b-fa b-fa-user-times',
              weight : 200,
              onItem : ({ eventRecord, resourceRecord }) => eventRecord.unassign(resourceRecord)
          }
      }
  }
}, // eo features
}

// eof
