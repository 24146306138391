<template>
  <div id="Planning">
    <div class="content-container">
      <scheduler
        ref="scheduler"
        :row-height="schedulerConfig.rowHeight"
        :bar-margin="schedulerConfig.barMargin"
        :event-color="schedulerConfig.eventColor"
        :start-date="schedulerConfig.startDate"
        :end-date="schedulerConfig.endDate"
        :event-store="schedulerConfig.eventStore"
        :columns="schedulerConfig.columns"
        :view-preset="schedulerConfig.viewPreset"
        :resources="schedulerConfig.resources"
        :time-ranges-feature="schedulerConfig.features.timeRanges"
        :events="schedulerConfig.events"
        :event-menu-feature="schedulerConfig.features.eventMenu"
        :resource-image-path="schedulerConfig.resourceImagePath"
        :min-height="schedulerConfig.minHeight"
      />
      <grid
        ref="grid"
        :config="gridConfig"
      />
    </div>
  </div>
</template>

<script>
import Scheduler from 'bryntum-vue-shared/src/Scheduler.vue'
import { Toast } from 'bryntum-scheduler'
import schedulerConfig from './components/schedulerConfig'
import Grid from './components/Grid.vue'
import Task from './lib/Task'
import Drag from './lib/Drag'

import 'bryntum-scheduler/scheduler.stockholm.css'

export default {
  name: 'Planning',

  components: {
    Scheduler,
    Grid,
  },

  data() {
    return {
      schedulerConfig,
      gridConfig: {
        ref: 'grid',
        store: {
          modelClass: Task,
          readUrl: '../data/unplanned.json',
          autoLoad: true,
        },
      },
    }
  }, // eo function data
  mounted() {
    const scheduler = this.$refs.scheduler.schedulerInstance;
   
    scheduler.eventStore.on({
        update  : this.onEventStoreUpdate,
        add     : this.onEventStoreAdd,
        thisObj : scheduler
    });

    new Drag({
        grid : this.$refs.grid.grid,
        schedule : this.$refs.scheduler.schedulerInstance,
        constrain : false,
        outerElement : this.$refs.grid.grid.element
    });

    Toast.show({
        timeout : 3500,
        html : 'Please note that this example uses the Bryntum Grid, which is licensed separately.'
    });

  }, // eo function mounted
  methods: {
      onEventStoreUpdate({ record }) {
                const scheduler = this.$refs.scheduler.schedulerInstance;
                if (scheduler.autoRescheduleTasks)  {
                    scheduler.eventStore.rescheduleOverlappingTasks(record);
                }
            }, // eo function onEventStoreUpdate

            // specific to this example - reschedules the tasks
            onEventStoreAdd({ records }) {
                const scheduler = this.$refs.scheduler.schedulerInstance;
                if (scheduler.autoRescheduleTasks)  {
                    records.forEach((eventRecord) => scheduler.eventStore.rescheduleOverlappingTasks(eventRecord));
                }
            } // eo function onEventStoreAdd
  },
} // eo export App/ eo export App

</script>

<style lang="scss">
    @import './App.scss';
</style>
