<!--
 * Unplanned grid component
-->
<template>
    <div class="grid-container"></div>
</template>

<script>
    import Grid from '../lib/UnplannedGrid';
    // export Grid
    export default {
        props: {
            config : Object
        },

        // runs once when component attached to DOM
        mounted() {
            const config = {
                ...this.config,
                appendTo   : this.$el,
                eventStore : this.$parent.$refs.scheduler.schedulerInstance.eventStore
            }
            console.log('mehdi')
            console.log(config)

            this.grid = new Grid(config);
        } // eo function mounted

    } // export the Grid

</script>

<!-- eof -->
